@import "colors.scss";
@import "mixins.scss";

.h3 {
  font-weight: 500;
  font-size: 1.25rem;
  width: 75%;
  margin-top: 3.25rem;
}

.background {
  display: flex;
  width: 100%;
  justify-content: center;
}

.card {
  width: 85%;
  max-width: 90rem;
  @media only screen and (max-width: 400px) {
    max-width: 100%;
  }
  @include phone {
    width: calc(100% - 1.25rem);
  }
  @include tablet-portrait {
    width: 85%;
    max-width: 90rem;
  }
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3.125rem;
}

.container {
  @include phone {
    width: calc(100% - 1.25rem);
    margin-left: 0.675rem;
    margin-right: 0.675rem;
  }
  @include tablet-portrait {
    width: calc(100% - 1.25rem);
    margin-left: 0.675rem;
    margin-right: 0.675rem;
  }
  @include tablet-landscape {
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
