@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir/Avenir.woff");
}
@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/AvenirNext/AvenirNext.woff");
}
* {
  box-sizing: border-box;
}

.container {
  width: 85%;
  max-width: 90rem;
  margin: 0 auto;
}
@media only screen and (max-width: 575px) {
  .container {
    width: 90%;
  }
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.spread-text {
  letter-spacing: 2.56px;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.pt-2 {
  padding-top: 2rem;
}

p a {
  color: #171717;
}

.disclaimer-wrapper p {
  color: #999999;
  font-size: 80%;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-wrapper.left {
  justify-content: flex-start;
}
.button-wrapper.right {
  justify-content: flex-end;
}

.btn {
  outline: 0;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  font-weight: 700;
  outline: 0;
  box-shadow: none;
  font-family: "Montserrat";
  width: auto;
  text-decoration: none;
  cursor: pointer;
}
.btn span {
  margin-left: 0.5rem;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled, .btn.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn.solid {
  color: black;
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, #fff200, #f2b923);
  padding: 1rem 2rem;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 1px 2px 7.84px 0.16px rgba(0, 0, 0, 0.17);
}
.btn.solid:hover {
  background-image: linear-gradient(to bottom, #e6da00, #e6c100);
}
.btn.simple {
  background: none;
  padding: 0;
}
.btn.twitter {
  color: #00ACEE;
  text-decoration: none;
  font-weight: normal;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn.twitter img {
  width: 16px;
}

.tile {
  padding: 2rem;
}

.nowrap {
  display: inline-block;
}

#home {
  font-family: "Avenir";
}
#home .hero-tile {
  background-image: url("../images/hero-background.jpg");
  width: 100%;
  background-position: top center;
  background-size: cover;
  color: white;
}
#home .hero-tile .desc-wrapper {
  min-height: 3rem;
  font-family: "Montserrat";
}
#home .hero-tile .header-wrapper {
  text-align: center;
}
#home .hero-tile .header-wrapper h1 {
  font-family: "AvenirNext";
  font-size: 3rem;
  font-weight: 600;
}
#home .hero-tile .header-wrapper h2 {
  letter-spacing: 2px;
  font-weight: 300;
}
#home .hero-tile .header-wrapper h2 a {
  color: #fff200;
  text-decoration: none;
  display: inline-block;
}
#home .hero-tile .header-wrapper h2 a strong {
  font-weight: 600;
}
#home .hero-tile .header-wrapper h2.divider-headline {
  font-size: 20px;
}
#home .keypoints-tile .keypoints-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 1199px) {
  #home .keypoints-tile .keypoints-wrapper {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  #home .keypoints-tile .keypoints-wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  font-family: "Montserrat";
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper a {
  color: #171717;
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .icon-wrapper {
  margin-bottom: 1rem;
  height: 8rem;
}
@media only screen and (max-width: 767px) {
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .icon-wrapper {
    height: auto;
  }
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .text-wrapper {
  padding: 0 2rem;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  border-right: 1px solid rgba(204, 204, 204, 0.45);
  height: 100%;
  max-width: 18rem;
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper:last-of-type .text-wrapper {
  border-right: 0;
}
@media only screen and (max-width: 1199px) {
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper {
    width: 50%;
    padding: 2rem;
  }
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .text-wrapper {
    padding: 0;
    border: 0;
  }
}
@media only screen and (max-width: 767px) {
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper {
    width: 100%;
  }
}
#home .keypoints-tile .title-wrapper {
  margin-bottom: 3rem;
}
#home .keypoints-tile .title-wrapper strong {
  font-weight: 800;
  font-family: "AvenirNext";
}
#home .quick-facts-tile {
  background-image: url("../images/facts-background.jpg");
  background-size: cover;
  background-position: center;
}
#home .quick-facts-tile .facts-wrapper ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
#home .quick-facts-tile .facts-wrapper ul li {
  padding: 0.5rem 1.5rem;
  width: 50%;
  list-style-type: none;
}
@media only screen and (max-width: 767px) {
  #home .quick-facts-tile .facts-wrapper ul li {
    width: 100%;
  }
}
#home .quick-facts-tile .facts-wrapper .fact-wrapper p {
  font-family: "Montserrat";
  line-height: 1.5;
  margin-bottom: 0.5rem;
}