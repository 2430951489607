@import "colors.scss";
@import "mixins.scss";

.dd {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  border: solid 1px $lightGrey;
  width: 100%;
  height: 3rem;
  flex-wrap: wrap;
  outline: none;
}

.header {
  width: 100%;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1em;
  label {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
  }
  .select {
    color: $lightGrey;
  }
  .selected {
    color: $darkGrey;
  }
}

.up {
  width: 0;
  height: 0;
  border-left: 0.625rem solid transparent;
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid $lightGrey;
  border-radius: 5px;
}

.down {
  width: 0;
  height: 0;
  border-left: 0.625rem solid transparent;
  border-right: 0.625rem solid transparent;
  border-top: 0.625rem solid $lightGrey;
  border-radius: 5px;
}

.list {
  width: 100%;
  max-height: 14.75em;
  margin-top: 0.25em;
  background-color: $white;
  border-radius: 10px;
  border: solid 1px $white;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.11);
  will-change: opacity;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  list-style-type: none;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    opacity: 0;
  }
  scrollbar-color: $lightGrey $white;
}

.item {
  color: $darkGrey;
  padding: 0.5em;
  padding-left: 1em;
  cursor: default;
  border-radius: 4px;
  @include Body-1GreyLeft;
}

.item:hover {
  background-color: $primaryYellow;
}
