@import "mixins.scss";

.div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  margin-top: 2rem;
  a {
    height: 1.5rem;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;

    div {
      border-left: 2px $darkGrey solid;
      border-top: 2px $darkGrey solid;
      height: 0.5rem;
      width: 0.5rem;
      transform: rotate(-45deg);
      margin-right: 0.75rem;
    }

    @include Text-LinkBlackLeft;
    text-decoration: underline;
    outline: none;
  }
}

.linkWrapper {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 5rem;
  width: 100%;

  .back {
    flex-basis: 50%;
  }

  .phone {
    text-decoration: none;
    flex-basis: 50%;
    font-size: 18px;
    @include phone {
      font-size: 16px;
      text-align: center;
    }
    img {
      height: 2rem;
      margin-right: 0.5rem;
    }
  }
}

.h2 {
  @include H2BlackLeft;
}

.recallsDiv {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.recall {
  @include Body-1GreyLeft;
  flex-basis: calc(100% / 3);

  @include phone {
    flex-basis: 100%;
    flex-shrink: 0;
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  h3 {
    @include H3BlackLeft;
    flex-basis: 100%;
    flex-shrink: 0;
  }
  p {
    align-self: center;
    padding-left: 0.25rem;
  }
  div {
    display: inline-flex;
    flex-basis: 100%;
    @include phone {
      flex-wrap: wrap;
    }
    @include tablet-portrait {
      flex-wrap: wrap;
    }
    @include tablet-landscape {
      flex-wrap: nowrap;
    }
  }
  a {
    height: unset;
    @include phone {
      margin-top: 1rem;
      flex-basis: 100%;
    }
    @include tablet-portrait {
      margin-top: 1rem;
      flex-basis: 100%;
    }
    @include tablet-landscape {
      margin-top: 0;
      margin-left: 6.25rem;
      flex-basis: auto;
    }
    img {
      height: 1.875rem;
      width: 1.875rem;
    }
  }
  .phone {
    font-weight: bold;
    flex-basis: unset;
    margin-top: 0;
    margin-left: 0;
  }
  img {
    padding-right: 0.5rem;
  }
}
