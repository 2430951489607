@import "colors.scss";
@import "mixins.scss";

.div {
  @include phone {
    flex-basis: 100%;
    flex-shrink: 0;
    padding-bottom: 0.5rem;
  }

  @include tablet-portrait {
    flex-basis: 100%;
    flex-shrink: 0;
  }

  @include tablet-landscape {
    flex-basis: 40%;
    flex-shrink: 1;
    height: 100%;
  }

  // button {
    // @include phone {
    //   width: 100%;
    //   margin-top: 1.875rem;
    // }
    // @include tablet-portrait {
    //   width: 100%;
    //   margin-top: 1.875rem;
    // }
    // @include tablet-landscape {
    //   // width: 13rem;
    // }
    // height: 3rem;
    // text-transform: uppercase;
    // font-size: 16px;
    // font-weight: bold;
    // text-align: center;
    // outline: none;
    // border: none;
    // margin-top: 1.875rem;
  // }

  .disabled {
    background-color: $darkGrey;
    opacity: 0.28;
    color: $white;
  }

  .enabled {
    cursor: pointer;
    color: $darkGrey;
    opacity: 1;
    background-image: linear-gradient(to bottom, $primaryYellow, #ffd600);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  }

  .enabled:hover {
    background-image: linear-gradient(to bottom, #e6da00, #e6c100);
  }
}

.h5 {
  @include H5WhiteLeft;
  margin: 0;
  padding: 0;
  padding-bottom: 1.25rem;
}

.body {
  @include Body-1GreyLeft;
  color: white;
  margin-top: 0;
  margin-bottom: 1.25rem;
}
