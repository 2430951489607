@import "colors.scss";
@import "mixins.scss";

.div {
  display: flex;
  align-content: baseline;
  flex-wrap: wrap;
  @include phone {
    flex-basis: 100%;
    flex-shrink: 0;
  }
  @include tablet-portrait {
    flex-basis: 100%;
    flex-shrink: 0;
  }
  @include tablet-landscape {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;
    flex-shrink: 0;
  }

  .h5 {
    @include H5BlackLeft;
    color: white;
    margin: 0;
    padding: 0;
    padding-bottom: 1.25rem;
  }

  & > label {
    font-weight: 500;
    font-size: 16px;
    flex-basis: 100%;
    flex-shrink: 0;
    color: white;
    margin-bottom: 1.25rem;
  }

  & > button {
    @include phone {
      width: 100%;
    }
    @include tablet-portrait {
      width: 100%;
    }
    @include tablet-landscape {
      width: 10.875rem;
    }
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    text-transform: uppercase;
    height: 3rem;
    border: none;
    outline: none;
    margin-bottom: 1.25rem;
  }

  .disabled {
    background-color: $darkGrey;
    opacity: 0.28;
    color: $white;
  }

  .enabled {
    color: $darkGrey;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to bottom, $primaryYellow, #ffd600);
    cursor: pointer;
  }

  .enabled:hover {
    background-image: linear-gradient(to bottom, #e6da00, #e6c100);
  }
}

.vin {
  position: relative;
  flex-basis: 100%;
  flex-shrink: 0;
  display: inline-flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 3rem;
  margin-bottom: 1.25rem;

  input {
    color: $darkGrey;
    border: 1px $darkGrey solid;
    border-radius: 10px;
    height: 3rem;
    flex-basis: calc(100% - 1rem);
    flex-shrink: 0;
    flex-grow: 1;
    font-family: Montserrat;
    font-weight: 700;
    padding-left: 1rem;
    outline: none;
  }

  input:focus {
    outline: none;
    border: 1px $darkGrey solid;
  }

  input::placeholder {
    color: $lightGrey;
    font-size: 16px;
    font-weight: 700;
  }

  label {
    color: $lightGrey;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    z-index: 2;
    right: 1.5rem;
    top: 1.1rem;
  }
}

.toolTip {
  flex-basis: 100%;
  flex-shrink: 0;
  outline: none;
  margin-bottom: 1.25rem;

  label {
    font-family: Montserrat;
    font-weight: normal;
    text-decoration: underline;
    outline: none;
    cursor: pointer;
  }

  .toolTipContent {
    position: absolute;
    margin-top: -13rem;
    margin-left: 4.5rem;
    z-index: 2;
    box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(23, 23, 23, 0.95);
    color: $white;
    font-size: 16px;
    font-weight: 500;
    height: 12.5rem;
    width: 20rem;
    transition: opacity 0.2s ease-in-out;

    p {
      margin: 1.875rem 1.5rem;
    }
  }
}
.esToolTip {
  margin-top: -18rem !important;
  height: 17.5rem !important;
}

.recaptcha {
  flex-basis: 100%;
  flex-shrink: 0;
  margin-bottom: 1.25rem;
}
