@import "colors.scss";
@import "mixins.scss";

.headercontainer {
  display: inline-flex;
  position: relative;
  background-color: $darkGrey;
  width: 100%;
  height: 4.25em;
  // border-bottom: 1px solid $white;
  flex-wrap: nowrap;
  align-items: center;
}

.menuBtn {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
    margin-right: 2rem;
  }
}

.menu {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 3;
  bottom: calc(-100vh - 1px);
  left: 0;
  background-color: $darkGrey;
  transition: transform 0.2s ease-out;
  outline: none;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    height: 4.25rem;
    border-bottom: 1px solid $white;
    cursor: pointer;
    p {
      font-size: 16px;
      font-weight: bold;
      color: $whitishGrey;
      background-color: rgba(0, 0, 0, 0);
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
    font-size: 16px;
    font-weight: bold;
    color: $whitishGrey;
    outline: none;
    text-decoration: none;
  }
  .item:hover {
    color: $primaryYellow;
  }
}

.langMenu {
  transition: height 0.1s ease-in-out;
  align-items: start !important;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  div {
    flex-basis: 100%;
  }
  p {
    padding-top: 1.6rem !important;
  }
  .lang {
    font-size: 16px !important;
    font-weight: normal !important;
    outline: none;
    cursor: pointer;
  }

  .lang:hover {
    color: $primaryYellow;
  }

  .activeLang {
    color: $primaryYellow !important;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.75rem;
    height: 0.75rem;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    margin-top: 1.75rem;
    margin-right: 2rem;
    transform: rotate(45deg);
    transition: transform 0.1s ease-in-out;
  }
}

.langMenu:hover {
  .langHeader {
    color: $primaryYellow;
  }
}

.openLang {
  height: 9.5rem !important;
  span {
    transform: rotate(135deg);
  }
  p:hover {
    color: $primaryYellow !important;
  }
}

.closedLang {
  height: 4.25rem;
}

.openMenu {
  transform: translateX(0);
}

.closedMenu {
  transform: translateX(100vw);
}

.logoLink {
  outline: none;
  @include phone {
    margin-left: 0.5rem;
  }
  @include tablet-portrait {
    margin-left: 0.5rem;
  }
  @include tablet-landscape {
    margin-left: 7.5%;
  }
}

.logo {
  width: 25rem;
  height: 1.875rem;

  @include phone {
    padding-top: 0.25rem;
    padding-right: 0.5rem;
    width: 15rem;
  }
}

.navDiv {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;

  div {
    display: inline-flex;
    flex-wrap: nowrap;
  }

  a {
    @include Body-1WhiteLeft;
    text-decoration: none;
    margin-bottom: 1em;
    outline: none;
    white-space: nowrap;
  }

  a:hover {
    color: $primaryYellow;
  }

  .navbutton {
    padding: 0.5rem;
    margin: 0rem 0.5rem;
  }

  .navbutton:hover {
    padding: 0.5rem;
    margin: 0rem 0.5rem;
  }

  .activenavlink {
    color: $primaryYellow;
  }
}

.langDiv {
  display: inline-flex;
  margin-right: 7.5rem;
  @include tablet-landscape {
    margin-right: 2rem;
  }
  button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    @include Body-1WhiteLeft;
  }

  .activeLang {
    color: $primaryYellow;
  }

  .inactiveLang {
    color: $white;
  }

  button:hover {
    color: $primaryYellow;
  }

  span {
    padding: 0.5rem 0rem;
    color: $white;
  }
}

.phoneWrapper{
  display: flex;
  flex-direction: column;
  text-align: right;
  .phoneLabel{
    text-transform: uppercase;
    color: $white;
    font-weight: 700;
    font-size: 15px;
  }
  .phone{
    a{
      font-size: 29px;
      color: $primaryYellow;
      font-weight: bold;
    }
  }
}