@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.App_app__3S3eq {
  font-family: Montserrat, sans-serif;
  color: #171717;
  scroll-behavior: smooth;
  overflow-x: hidden;
  outline: none;
}
@media (min-width: 1200px) {
  .App_app__3S3eq {
    min-height: calc(100vh - 15rem);
    position: relative;
    padding-bottom: 15rem;
  }
}
.Header_headercontainer__2UXQe {
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
  background-color: #171717;
  width: 100%;
  height: 4.25em;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
}

.Header_menuBtn__2z0_s {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.Header_menuBtn__2z0_s img {
  cursor: pointer;
  margin-right: 2rem;
}

.Header_menu__mB7gY {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 3;
  bottom: calc(-100vh - 1px);
  left: 0;
  background-color: #171717;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  outline: none;
}
.Header_menu__mB7gY .Header_item__1KxJT {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 1rem;
  height: 4.25rem;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #f6f5f3;
  outline: none;
  text-decoration: none;
}
.Header_menu__mB7gY .Header_item__1KxJT p {
  font-size: 16px;
  font-weight: bold;
  color: #f6f5f3;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.Header_menu__mB7gY .Header_item__1KxJT:hover {
  color: #fff200;
}

.Header_langMenu__36yPr {
  transition: height 0.1s ease-in-out;
  -webkit-align-items: start !important;
          align-items: start !important;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow: hidden;
}
.Header_langMenu__36yPr div {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
.Header_langMenu__36yPr p {
  padding-top: 1.6rem !important;
}
.Header_langMenu__36yPr .Header_lang__1PJkZ {
  font-size: 16px !important;
  font-weight: normal !important;
  outline: none;
  cursor: pointer;
}
.Header_langMenu__36yPr .Header_lang__1PJkZ:hover {
  color: #fff200;
}
.Header_langMenu__36yPr .Header_activeLang__wfKMz {
  color: #fff200 !important;
}
.Header_langMenu__36yPr span {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-top: 1.75rem;
  margin-right: 2rem;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.Header_langMenu__36yPr:hover .Header_langHeader__5Yv_t {
  color: #fff200;
}

.Header_openLang__3qQGj {
  height: 9.5rem !important;
}
.Header_openLang__3qQGj span {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.Header_openLang__3qQGj p:hover {
  color: #fff200 !important;
}

.Header_closedLang__Wh74F {
  height: 4.25rem;
}

.Header_openMenu__3LywL {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.Header_closedMenu__3vYrw {
  -webkit-transform: translateX(100vw);
          transform: translateX(100vw);
}

.Header_logoLink__16LS1 {
  outline: none;
}
@media (max-width: 599px) {
  .Header_logoLink__16LS1 {
    margin-left: 0.5rem;
  }
}
@media (min-width: 600px) {
  .Header_logoLink__16LS1 {
    margin-left: 0.5rem;
  }
}
@media (min-width: 900px) {
  .Header_logoLink__16LS1 {
    margin-left: 7.5%;
  }
}

.Header_logo__1fFsH {
  width: 25rem;
  height: 1.875rem;
}
@media (max-width: 599px) {
  .Header_logo__1fFsH {
    padding-top: 0.25rem;
    padding-right: 0.5rem;
    width: 15rem;
  }
}

.Header_navDiv__2wqbQ {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.Header_navDiv__2wqbQ div {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.Header_navDiv__2wqbQ a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 1em;
  outline: none;
  white-space: nowrap;
}
.Header_navDiv__2wqbQ a:hover {
  color: #fff200;
}
.Header_navDiv__2wqbQ .Header_navbutton__1y4fK {
  padding: 0.5rem;
  margin: 0rem 0.5rem;
}
.Header_navDiv__2wqbQ .Header_navbutton__1y4fK:hover {
  padding: 0.5rem;
  margin: 0rem 0.5rem;
}
.Header_navDiv__2wqbQ .Header_activenavlink__1jHXx {
  color: #fff200;
}

.Header_langDiv__2EoxK {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 7.5rem;
}
@media (min-width: 900px) {
  .Header_langDiv__2EoxK {
    margin-right: 2rem;
  }
}
.Header_langDiv__2EoxK button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}
.Header_langDiv__2EoxK .Header_activeLang__wfKMz {
  color: #fff200;
}
.Header_langDiv__2EoxK .Header_inactiveLang__21pyJ {
  color: #ffffff;
}
.Header_langDiv__2EoxK button:hover {
  color: #fff200;
}
.Header_langDiv__2EoxK span {
  padding: 0.5rem 0rem;
  color: #ffffff;
}

.Header_phoneWrapper__12ztB {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: right;
}
.Header_phoneWrapper__12ztB .Header_phoneLabel__tw_Oq {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;
}
.Header_phoneWrapper__12ztB .Header_phone__1YoEN a {
  font-size: 29px;
  color: #fff200;
  font-weight: bold;
}
@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir.eee10c33.woff);
}
@font-face {
  font-family: "AvenirNext";
  src: url(/static/media/AvenirNext.d74b9daa.woff);
}
.Footer_div__3QIHo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  background-color: #171717;
}
@media (min-width: 1200px) {
  .Footer_div__3QIHo {
    position: absolute;
    bottom: 0;
  }
}
@media (max-width: 599px) {
  .Footer_div__3QIHo {
    height: unset;
  }
}
@media (min-width: 600px) {
  .Footer_div__3QIHo {
    height: unset;
  }
}
@media (min-width: 900px) {
  .Footer_div__3QIHo {
    height: 15rem;
  }
}

.Footer_content__d5T86 {
  width: 85%;
  max-width: 90rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
@media (max-width: 599px) {
  .Footer_content__d5T86 {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 1rem;
  }
}
@media (min-width: 600px) {
  .Footer_content__d5T86 {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 2rem;
  }
}
@media (min-width: 900px) {
  .Footer_content__d5T86 {
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 2rem;
  }
}

.Footer_contactDiv__1ATva {
  height: 6rem;
  width: 13.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 2rem;
}
@media (max-width: 599px) {
  .Footer_contactDiv__1ATva {
    margin-top: 1rem;
  }
}
@media (min-width: 600px) {
  .Footer_contactDiv__1ATva {
    margin-top: 1rem;
  }
}
@media (min-width: 900px) {
  .Footer_contactDiv__1ATva {
    margin-top: 0;
  }
}
.Footer_contactDiv__1ATva a {
  height: 1rem;
  width: 1rem;
  outline: none;
  margin-top: 1.5rem;
}

.Footer_logo__GQgOo {
  width: 13.75rem;
  height: 1rem;
}

.Footer_contact__1CJIg {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}

.Footer_YTLogo__3DKsC {
  color: #ffffff;
  height: 1.5rem;
  width: 1.875rem;
}

.Footer_Section__NMMhS {
  width: 13.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .Footer_Section__NMMhS {
    width: 100%;
  }
}
@media (max-width: 599px) {
  .Footer_Section__NMMhS {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 600px) {
  .Footer_Section__NMMhS {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.Footer_Section__NMMhS p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff200;
  margin-top: 0;
}
.Footer_Section__NMMhS a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 1em;
  outline: none;
}
.Footer_Section__NMMhS a:hover {
  color: #fff200;
}
.Footer_Section__NMMhS a:last-child {
  margin-bottom: 0;
}
.CardContainer_h3__36HS6 {
  font-weight: 500;
  font-size: 1.25rem;
  width: 75%;
  margin-top: 3.25rem;
}

.CardContainer_background__3EMzj {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.CardContainer_card__2su7L {
  width: 85%;
  max-width: 90rem;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 3.125rem;
}
@media only screen and (max-width: 400px) {
  .CardContainer_card__2su7L {
    max-width: 100%;
  }
}
@media (max-width: 599px) {
  .CardContainer_card__2su7L {
    width: calc(100% - 1.25rem);
  }
}
@media (min-width: 600px) {
  .CardContainer_card__2su7L {
    width: 85%;
    max-width: 90rem;
  }
}

@media (max-width: 599px) {
  .CardContainer_container__2uVZA {
    width: calc(100% - 1.25rem);
    margin-left: 0.675rem;
    margin-right: 0.675rem;
  }
}
@media (min-width: 600px) {
  .CardContainer_container__2uVZA {
    width: calc(100% - 1.25rem);
    margin-left: 0.675rem;
    margin-right: 0.675rem;
  }
}
@media (min-width: 900px) {
  .CardContainer_container__2uVZA {
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
.MakeResults_div__2tbFe {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  margin-top: 2rem;
}
.MakeResults_div__2tbFe a {
  height: 1.5rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.57;
  color: #000000;
  text-decoration: underline;
  outline: none;
}
.MakeResults_div__2tbFe a div {
  border-left: 2px #171717 solid;
  border-top: 2px #171717 solid;
  height: 0.5rem;
  width: 0.5rem;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 0.75rem;
}

.MakeResults_linkWrapper__2O0Rd {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 5rem;
  width: 100%;
}
.MakeResults_linkWrapper__2O0Rd .MakeResults_back__PgNFJ {
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
}
.MakeResults_linkWrapper__2O0Rd .MakeResults_phone__5lglK {
  text-decoration: none;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  font-size: 18px;
}
@media (max-width: 599px) {
  .MakeResults_linkWrapper__2O0Rd .MakeResults_phone__5lglK {
    font-size: 16px;
    text-align: center;
  }
}
.MakeResults_linkWrapper__2O0Rd .MakeResults_phone__5lglK img {
  height: 2rem;
  margin-right: 0.5rem;
}

.MakeResults_h2__1j8Tj {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  color: #000000;
}

.MakeResults_recallsDiv__26_Qg {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.MakeResults_recall__1UePN {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  color: #474545;
  -webkit-flex-basis: calc(100% / 3);
          flex-basis: calc(100% / 3);
}
@media (max-width: 599px) {
  .MakeResults_recall__1UePN {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }
}

.MakeResults_links__3OLd_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
.MakeResults_links__3OLd_ h3 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: #171717;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.MakeResults_links__3OLd_ p {
  -webkit-align-self: center;
          align-self: center;
  padding-left: 0.25rem;
}
.MakeResults_links__3OLd_ div {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
@media (max-width: 599px) {
  .MakeResults_links__3OLd_ div {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}
@media (min-width: 600px) {
  .MakeResults_links__3OLd_ div {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}
@media (min-width: 900px) {
  .MakeResults_links__3OLd_ div {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}
.MakeResults_links__3OLd_ a {
  height: unset;
}
@media (max-width: 599px) {
  .MakeResults_links__3OLd_ a {
    margin-top: 1rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }
}
@media (min-width: 600px) {
  .MakeResults_links__3OLd_ a {
    margin-top: 1rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }
}
@media (min-width: 900px) {
  .MakeResults_links__3OLd_ a {
    margin-top: 0;
    margin-left: 6.25rem;
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
}
.MakeResults_links__3OLd_ a img {
  height: 1.875rem;
  width: 1.875rem;
}
.MakeResults_links__3OLd_ .MakeResults_phone__5lglK {
  font-weight: bold;
  -webkit-flex-basis: unset;
          flex-basis: unset;
  margin-top: 0;
  margin-left: 0;
}
.MakeResults_links__3OLd_ img {
  padding-right: 0.5rem;
}
.SubHeader_div__72AZz {
  margin-top: 2.5rem;
}
.SubHeader_div__72AZz .SubHeader_h2__XkC-H {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  color: #000000;
  margin: 0;
  margin-bottom: 1.875rem;
}
.SubHeader_div__72AZz .SubHeader_hr__3euPa {
  border: 2px solid #fff200;
  background-color: #fff200;
  border-width: 3px;
  box-shadow: none;
  outline: none;
  margin: 0;
}
.VINResults_content__n3KXk {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-top: 2rem;
}
.VINResults_content__n3KXk a {
  height: 1.5rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.57;
  color: #000000;
  text-decoration: underline;
  outline: none;
}
.VINResults_content__n3KXk a div {
  border-left: 2px #171717 solid;
  border-top: 2px #171717 solid;
  height: 0.5rem;
  width: 0.5rem;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 0.75rem;
}

.VINResults_header__1_hFw {
  font-size: 26px;
  font-weight: bold;
  line-height: normal;
  margin-top: 3.125rem;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.VINResults_header__1_hFw svg {
  margin-right: 1.25rem;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.VINResults_TakataFound__35Whb {
  color: #ef2b12;
}

.VINResults_NoTakata__3ZnM9 {
  color: #319c0d;
}

.VINResults_infobar__2c1hs {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
@media (max-width: 599px) {
  .VINResults_infobar__2c1hs {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}
@media (min-width: 600px) {
  .VINResults_infobar__2c1hs {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}
@media (min-width: 900px) {
  .VINResults_infobar__2c1hs {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    margin-top: 3.125rem;
  }
}
.VINResults_infobar__2c1hs div {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
}
@media (max-width: 599px) {
  .VINResults_infobar__2c1hs div {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    margin-top: 1.25rem;
  }
}
@media (min-width: 600px) {
  .VINResults_infobar__2c1hs div {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    margin-top: 1.25rem;
  }
}
@media (min-width: 900px) {
  .VINResults_infobar__2c1hs div {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
  }
}
.VINResults_infobar__2c1hs div label {
  font-size: 16px;
  font-weight: bold;
}
.VINResults_infobar__2c1hs div p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  color: #474545;
  margin: 0;
}

.VINResults_recall__PvaRQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.VINResults_recall__PvaRQ label {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  font-size: 16px;
  font-weight: bold;
  margin-top: 1.25rem;
}
.VINResults_recall__PvaRQ p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  color: #474545;
  margin: 0;
  margin-top: 0.25rem;
}
.VINResults_recall__PvaRQ hr {
  width: 100%;
  margin-top: 1.25rem;
}

.VINResults_whatToDo__20KmZ {
  position: relative;
  border-left: 4px solid #ef2b12;
  padding-left: 1rem;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.VINResults_whatToDo__20KmZ h5 {
  margin: 0;
}
.VINResults_whatToDo__20KmZ img {
  height: 1.875rem;
  width: 1.875rem;
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 {
  margin-top: 1.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 h3 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: #171717;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 p {
  -webkit-align-self: center;
          align-self: center;
  padding-left: 0.25rem;
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 div {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
@media (max-width: 599px) {
  .VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 div {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}
@media (min-width: 600px) {
  .VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 div {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}
@media (min-width: 900px) {
  .VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 div {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 a {
  height: unset;
}
@media (max-width: 599px) {
  .VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 a {
    margin-top: 1rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }
}
@media (min-width: 600px) {
  .VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 a {
    margin-top: 1rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }
}
@media (min-width: 900px) {
  .VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 a {
    margin-top: 0;
    margin-left: 6.25rem;
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 a img {
  height: 1.25rem;
  width: 1.25rem;
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 .VINResults_phone__7Iu1N {
  text-decoration: none;
  font-weight: 500;
  -webkit-flex-basis: unset;
          flex-basis: unset;
  margin-top: 0;
  margin-left: 0;
}
.VINResults_whatToDo__20KmZ .VINResults_links__Rdad4 img {
  padding-right: 0.5rem;
}

.VINResults_loader__2Rnfp {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.CookieDisclosure_container__2Xwiq.CookieDisclosure_hidden__D-mIp {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.CookieDisclosure_container__2Xwiq.CookieDisclosure_mobile__2ptzL {
  height: auto;
}

.CookieDisclosure_container__2Xwiq.CookieDisclosure_mobile__2ptzL.CookieDisclosure_open__LT4kr {
  height: auto;
}

.CookieDisclosure_container__2Xwiq {
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100vw;
  height: auto;
  background-color: #171717;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  transition: height 0.25s ease-out, -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out, height 0.25s ease-out;
  transition: transform 0.25s ease-out, height 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.CookieDisclosure_copy__14KJ7 p {
  line-height: 1.125rem;
}

.CookieDisclosure_desktopContent__CyI4f {
  -webkit-flex-basis: 85%;
          flex-basis: 85%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  max-width: 90rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: normal;
          justify-content: normal;
}
.CookieDisclosure_desktopContent__CyI4f .CookieDisclosure_copy__14KJ7 {
  width: 75%;
}
.CookieDisclosure_desktopContent__CyI4f .CookieDisclosure_copy__14KJ7 h4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff200;
  text-align: left;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  margin-bottom: 0;
}
.CookieDisclosure_desktopContent__CyI4f .CookieDisclosure_copy__14KJ7 .CookieDisclosure_en__3XwvC {
  margin-top: 1rem;
}
.CookieDisclosure_desktopContent__CyI4f .CookieDisclosure_copy__14KJ7 .CookieDisclosure_es__3JtZ- {
  margin-top: 0.5rem;
}
.CookieDisclosure_desktopContent__CyI4f .CookieDisclosure_copy__14KJ7 p {
  margin-top: 0.25rem;
  color: #ffffff;
  font-size: 12px;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
.CookieDisclosure_desktopContent__CyI4f .CookieDisclosure_copy__14KJ7 p a {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  color: white;
  outline: none;
}
.CookieDisclosure_desktopContent__CyI4f .CookieDisclosure_consent__3UxES {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 2rem;
  margin-right: 0.75rem;
}

.CookieDisclosure_closedMobileContent__VmbOU {
  -webkit-flex-basis: calc(100% - 1rem);
          flex-basis: calc(100% - 1rem);
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  max-width: 90rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: normal;
          justify-content: normal;
}
.CookieDisclosure_closedMobileContent__VmbOU .CookieDisclosure_readMore__35Hoi {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.CookieDisclosure_closedMobileContent__VmbOU .CookieDisclosure_copy__14KJ7 {
  width: 50%;
  margin-right: 0.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: center;
          align-content: center;
}
.CookieDisclosure_closedMobileContent__VmbOU .CookieDisclosure_copy__14KJ7 h4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff200;
  text-align: left;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.CookieDisclosure_closedMobileContent__VmbOU .CookieDisclosure_copy__14KJ7 p {
  margin-top: 0.25rem;
  color: #ffffff;
  font-size: 12px;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
.CookieDisclosure_closedMobileContent__VmbOU .CookieDisclosure_copy__14KJ7 p a {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  color: white;
  outline: none;
}
.CookieDisclosure_closedMobileContent__VmbOU .CookieDisclosure_consent__3UxES {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.CookieDisclosure_openMobileContent__1ncIA {
  -webkit-flex-basis: calc(100% - 1rem);
          flex-basis: calc(100% - 1rem);
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  max-width: 90rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
}
.CookieDisclosure_openMobileContent__1ncIA .CookieDisclosure_copy__14KJ7 {
  width: 100%;
  margin-right: 0.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.CookieDisclosure_openMobileContent__1ncIA .CookieDisclosure_copy__14KJ7 .CookieDisclosure_collapse__1FTO3 {
  cursor: pointer;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  padding-top: 0.75rem;
}
.CookieDisclosure_openMobileContent__1ncIA .CookieDisclosure_copy__14KJ7 h4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff200;
  text-align: left;
  -webkit-flex-basis: 75%;
          flex-basis: 75%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.CookieDisclosure_openMobileContent__1ncIA .CookieDisclosure_copy__14KJ7 p {
  margin-top: 0.25rem;
  color: #ffffff;
  font-size: 12px;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
.CookieDisclosure_openMobileContent__1ncIA .CookieDisclosure_copy__14KJ7 p a {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  color: white;
  outline: none;
}
.CookieDisclosure_openMobileContent__1ncIA .CookieDisclosure_consent__3UxES {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}
.SearchBoxes_searchBoxes__1na5j {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-top: 2rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.SearchBoxes_vertBoxes__3Zyk8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-top: 1.25rem;
}

.SearchBoxes_vertSeperator__13CkO {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.SearchBoxes_vertSeperator__13CkO hr {
  width: 50%;
}
.SearchBoxes_vertSeperator__13CkO label {
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  margin-left: 0.5rem;
  margin-top: -0.2rem;
  padding: 0 0.5rem;
}

.SearchBoxes_separator__1xyMT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 2.5rem;
}
.SearchBoxes_separator__1xyMT hr {
  height: 40%;
  margin: 0;
  margin-left: 1em;
}
.SearchBoxes_separator__1xyMT label {
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.VINSearch_div__3vuna {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: baseline;
          align-content: baseline;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
@media (max-width: 599px) {
  .VINSearch_div__3vuna {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }
}
@media (min-width: 600px) {
  .VINSearch_div__3vuna {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }
}
@media (min-width: 900px) {
  .VINSearch_div__3vuna {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-basis: 40%;
            flex-basis: 40%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }
}
.VINSearch_div__3vuna .VINSearch_h5___rZTS {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  color: #171717;
  margin: 0;
  color: white;
  margin: 0;
  padding: 0;
  padding-bottom: 1.25rem;
}
.VINSearch_div__3vuna > label {
  font-weight: 500;
  font-size: 16px;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  color: white;
  margin-bottom: 1.25rem;
}
.VINSearch_div__3vuna > button {
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  text-transform: uppercase;
  height: 3rem;
  border: none;
  outline: none;
  margin-bottom: 1.25rem;
}
@media (max-width: 599px) {
  .VINSearch_div__3vuna > button {
    width: 100%;
  }
}
@media (min-width: 600px) {
  .VINSearch_div__3vuna > button {
    width: 100%;
  }
}
@media (min-width: 900px) {
  .VINSearch_div__3vuna > button {
    width: 10.875rem;
  }
}
.VINSearch_div__3vuna .VINSearch_disabled__1nnkG {
  background-color: #171717;
  opacity: 0.28;
  color: #ffffff;
}
.VINSearch_div__3vuna .VINSearch_enabled__msna6 {
  color: #171717;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to bottom, #fff200, #ffd600);
  cursor: pointer;
}
.VINSearch_div__3vuna .VINSearch_enabled__msna6:hover {
  background-image: linear-gradient(to bottom, #e6da00, #e6c100);
}

.VINSearch_vin__1qq-z {
  position: relative;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  white-space: nowrap;
  height: 3rem;
  margin-bottom: 1.25rem;
}
.VINSearch_vin__1qq-z input {
  color: #171717;
  border: 1px #171717 solid;
  border-radius: 10px;
  height: 3rem;
  -webkit-flex-basis: calc(100% - 1rem);
          flex-basis: calc(100% - 1rem);
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  font-family: Montserrat;
  font-weight: 700;
  padding-left: 1rem;
  outline: none;
}
.VINSearch_vin__1qq-z input:focus {
  outline: none;
  border: 1px #171717 solid;
}
.VINSearch_vin__1qq-z input::-webkit-input-placeholder {
  color: rgba(23, 23, 23, 0.5);
  font-size: 16px;
  font-weight: 700;
}
.VINSearch_vin__1qq-z input:-ms-input-placeholder {
  color: rgba(23, 23, 23, 0.5);
  font-size: 16px;
  font-weight: 700;
}
.VINSearch_vin__1qq-z input::placeholder {
  color: rgba(23, 23, 23, 0.5);
  font-size: 16px;
  font-weight: 700;
}
.VINSearch_vin__1qq-z label {
  color: rgba(23, 23, 23, 0.5);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  z-index: 2;
  right: 1.5rem;
  top: 1.1rem;
}

.VINSearch_toolTip__3upCm {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  outline: none;
  margin-bottom: 1.25rem;
}
.VINSearch_toolTip__3upCm label {
  font-family: Montserrat;
  font-weight: normal;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
}
.VINSearch_toolTip__3upCm .VINSearch_toolTipContent__2CH0s {
  position: absolute;
  margin-top: -13rem;
  margin-left: 4.5rem;
  z-index: 2;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(23, 23, 23, 0.95);
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  height: 12.5rem;
  width: 20rem;
  transition: opacity 0.2s ease-in-out;
}
.VINSearch_toolTip__3upCm .VINSearch_toolTipContent__2CH0s p {
  margin: 1.875rem 1.5rem;
}

.VINSearch_esToolTip__3jiwD {
  margin-top: -18rem !important;
  height: 17.5rem !important;
}

.VINSearch_recaptcha__10VU1 {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-bottom: 1.25rem;
}
@media (max-width: 599px) {
  .MakeSearch_div__1_Usq {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 600px) {
  .MakeSearch_div__1_Usq {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
  }
}
@media (min-width: 900px) {
  .MakeSearch_div__1_Usq {
    -webkit-flex-basis: 40%;
            flex-basis: 40%;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    height: 100%;
  }
}
.MakeSearch_div__1_Usq .MakeSearch_disabled__2Om3v {
  background-color: #171717;
  opacity: 0.28;
  color: #ffffff;
}
.MakeSearch_div__1_Usq .MakeSearch_enabled__32IQZ {
  cursor: pointer;
  color: #171717;
  opacity: 1;
  background-image: linear-gradient(to bottom, #fff200, #ffd600);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
}
.MakeSearch_div__1_Usq .MakeSearch_enabled__32IQZ:hover {
  background-image: linear-gradient(to bottom, #e6da00, #e6c100);
}

.MakeSearch_h5__1eGqw {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  padding: 0;
  padding-bottom: 1.25rem;
}

.MakeSearch_body__2Bya0 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  color: #474545;
  color: white;
  margin-top: 0;
  margin-bottom: 1.25rem;
}
.DropDown_dd__fd3iZ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px rgba(23, 23, 23, 0.5);
  width: 100%;
  height: 3rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  outline: none;
}

.DropDown_header__1cD34 {
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 1em;
}
.DropDown_header__1cD34 label {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}
.DropDown_header__1cD34 .DropDown_select__3oEL4 {
  color: rgba(23, 23, 23, 0.5);
}
.DropDown_header__1cD34 .DropDown_selected__e6EGQ {
  color: #171717;
}

.DropDown_up__2y7Ps {
  width: 0;
  height: 0;
  border-left: 0.625rem solid transparent;
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid rgba(23, 23, 23, 0.5);
  border-radius: 5px;
}

.DropDown_down__134aa {
  width: 0;
  height: 0;
  border-left: 0.625rem solid transparent;
  border-right: 0.625rem solid transparent;
  border-top: 0.625rem solid rgba(23, 23, 23, 0.5);
  border-radius: 5px;
}

.DropDown_list__3eiCn {
  width: 100%;
  max-height: 14.75em;
  margin-top: 0.25em;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #ffffff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.11);
  will-change: opacity;
  transition: opacity 0.2s ease-in-out;
  list-style-type: none;
  overflow-y: scroll;
  scrollbar-color: rgba(23, 23, 23, 0.5) #ffffff;
}
.DropDown_list__3eiCn ::-webkit-scrollbar-track {
  opacity: 0;
}

.DropDown_item__vKn_G {
  color: #171717;
  padding: 0.5em;
  padding-left: 1em;
  cursor: default;
  border-radius: 4px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  color: #474545;
}

.DropDown_item__vKn_G:hover {
  background-color: #fff200;
}
.PrivacyPolicy_privacyPolicyContainer__2o84e {
  margin: 0 auto;
  max-width: 1440px;
  padding: 20px 20px 40px 20px;
}
@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir.eee10c33.woff);
}
@font-face {
  font-family: "AvenirNext";
  src: url(/static/media/AvenirNext.d74b9daa.woff);
}
* {
  box-sizing: border-box;
}

.container {
  width: 85%;
  max-width: 90rem;
  margin: 0 auto;
}
@media only screen and (max-width: 575px) {
  .container {
    width: 90%;
  }
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.spread-text {
  letter-spacing: 2.56px;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.pt-2 {
  padding-top: 2rem;
}

p a {
  color: #171717;
}

.disclaimer-wrapper p {
  color: #999999;
  font-size: 80%;
}

.button-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.button-wrapper.left {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.button-wrapper.right {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.btn {
  outline: 0;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  font-weight: 700;
  outline: 0;
  box-shadow: none;
  font-family: "Montserrat";
  width: auto;
  text-decoration: none;
  cursor: pointer;
}
.btn span {
  margin-left: 0.5rem;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled, .btn.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn.solid {
  color: black;
  text-transform: uppercase;
  background-image: linear-gradient(to bottom, #fff200, #f2b923);
  padding: 1rem 2rem;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 1px 2px 7.84px 0.16px rgba(0, 0, 0, 0.17);
}
.btn.solid:hover {
  background-image: linear-gradient(to bottom, #e6da00, #e6c100);
}
.btn.simple {
  background: none;
  padding: 0;
}
.btn.twitter {
  color: #00ACEE;
  text-decoration: none;
  font-weight: normal;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.btn.twitter img {
  width: 16px;
}

.tile {
  padding: 2rem;
}

.nowrap {
  display: inline-block;
}

#home {
  font-family: "Avenir";
}
#home .hero-tile {
  background-image: url(/static/media/hero-background.d994871a.jpg);
  width: 100%;
  background-position: top center;
  background-size: cover;
  color: white;
}
#home .hero-tile .desc-wrapper {
  min-height: 3rem;
  font-family: "Montserrat";
}
#home .hero-tile .header-wrapper {
  text-align: center;
}
#home .hero-tile .header-wrapper h1 {
  font-family: "AvenirNext";
  font-size: 3rem;
  font-weight: 600;
}
#home .hero-tile .header-wrapper h2 {
  letter-spacing: 2px;
  font-weight: 300;
}
#home .hero-tile .header-wrapper h2 a {
  color: #fff200;
  text-decoration: none;
  display: inline-block;
}
#home .hero-tile .header-wrapper h2 a strong {
  font-weight: 600;
}
#home .hero-tile .header-wrapper h2.divider-headline {
  font-size: 20px;
}
#home .keypoints-tile .keypoints-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 1199px) {
  #home .keypoints-tile .keypoints-wrapper {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  #home .keypoints-tile .keypoints-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper {
  width: 25%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  font-family: "Montserrat";
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper a {
  color: #171717;
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .icon-wrapper {
  margin-bottom: 1rem;
  height: 8rem;
}
@media only screen and (max-width: 767px) {
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .icon-wrapper {
    height: auto;
  }
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .text-wrapper {
  padding: 0 2rem;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  border-right: 1px solid rgba(204, 204, 204, 0.45);
  height: 100%;
  max-width: 18rem;
}
#home .keypoints-tile .keypoints-wrapper .keypoint-wrapper:last-of-type .text-wrapper {
  border-right: 0;
}
@media only screen and (max-width: 1199px) {
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper {
    width: 50%;
    padding: 2rem;
  }
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper .text-wrapper {
    padding: 0;
    border: 0;
  }
}
@media only screen and (max-width: 767px) {
  #home .keypoints-tile .keypoints-wrapper .keypoint-wrapper {
    width: 100%;
  }
}
#home .keypoints-tile .title-wrapper {
  margin-bottom: 3rem;
}
#home .keypoints-tile .title-wrapper strong {
  font-weight: 800;
  font-family: "AvenirNext";
}
#home .quick-facts-tile {
  background-image: url(/static/media/facts-background.afd644f5.jpg);
  background-size: cover;
  background-position: center;
}
#home .quick-facts-tile .facts-wrapper ul {
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
#home .quick-facts-tile .facts-wrapper ul li {
  padding: 0.5rem 1.5rem;
  width: 50%;
  list-style-type: none;
}
@media only screen and (max-width: 767px) {
  #home .quick-facts-tile .facts-wrapper ul li {
    width: 100%;
  }
}
#home .quick-facts-tile .facts-wrapper .fact-wrapper p {
  font-family: "Montserrat";
  line-height: 1.5;
  margin-bottom: 0.5rem;
}
