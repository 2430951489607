@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir/Avenir.woff');
}
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext/AvenirNext.woff');
}

$font-avenir: 'Avenir';
$font-avenir-next: 'AvenirNext';
$font-montserrat: 'Montserrat';

$breakpoints: (
  "xs": 480px,
  "sm": 576px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1200px,
);
