$darkGrey: #171717;
$black: #000000;
$lightGrey: rgba(23, 23, 23, 0.5);
$white: #ffffff;
$primaryYellow: #fff200;
$whitishGrey: #f6f5f3;
$green: #319c0d;
$red: #ef2b12;
$lighterGrey: #474545;
$twitter: #00ACEE;
