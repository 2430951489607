@import "colors.scss";
@import "variables.scss";
@import "mixins.scss";

.div {
  @include desktop {
    position: absolute;
    bottom: 0;
  }
  display: flex;
  justify-content: center;
  width: 100%;
  @include phone {
    height: unset;
  }
  @include tablet-portrait {
    height: unset;
  }
  @include tablet-landscape {
    height: 15rem;
  }
  background-color: $darkGrey;
}

.content {
  width: 85%;
  max-width: 90rem;
  display: inline-flex;
  justify-content: space-evenly;

  @include phone {
    flex-direction: column;
    margin-top: 1rem;
  }

  @include tablet-portrait {
    flex-direction: column;
    margin-top: 2rem;
  }

  @include tablet-landscape {
    flex-direction: row;
    margin-top: 2rem;
  }
}

.contactDiv {
  height: 6rem;
  width: 13.75rem;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  margin-right: 2rem;
  @include phone {
    margin-top: 1rem;
  }
  @include tablet-portrait {
    margin-top: 1rem;
  }
  @include tablet-landscape {
    margin-top: 0;
  }

  a {
    height: 1rem;
    width: 1rem;
    outline: none;
    margin-top: 1.5rem;
  }
}

.logo {
  width: 13.75rem;
  height: 1rem;
}

.contact {
  @include Body-1WhiteLeft;
  margin: 0;
}

.YTLogo {
  color: $white;
  height: 1.5rem;
  width: 1.875rem;
}

.Section {
  width: 13.75rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @include mq('md'){
    width: 100%;
  }

  @include phone {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @include tablet-portrait {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    @include Footer-HeaderYellow;
    margin-top: 0;
  }

  a {
    @include Body-1WhiteLeft;
    text-decoration: none;
    margin-bottom: 1em;
    outline: none;
  }
  a:hover {
    color: $primaryYellow;
  }
  a:last-child {
    margin-bottom: 0;
  }
}

