@import "colors.scss";
@import "mixins.scss";

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;

  a {
    height: 1.5rem;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;

    div {
      border-left: 2px $darkGrey solid;
      border-top: 2px $darkGrey solid;
      height: 0.5rem;
      width: 0.5rem;
      transform: rotate(-45deg);
      margin-right: 0.75rem;
    }

    @include Text-LinkBlackLeft;
    text-decoration: underline;
    outline: none;
  }
}

.header {
  font-size: 26px;
  font-weight: bold;
  line-height: normal;
  margin-top: 3.125rem;
  flex-basis: 100%;
  flex-shrink: 0;
  display: flex;
  flex-wrap: nowrap;
  svg {
    margin-right: 1.25rem;
    flex-shrink: 0;
  }
}

.TakataFound {
  color: $red;
}

.NoTakata {
  color: $green;
}

.infobar {
  display: inline-flex;
  @include phone {
    flex-wrap: wrap;
  }
  @include tablet-portrait {
    flex-wrap: wrap;
  }
  @include tablet-landscape {
    flex-wrap: nowrap;
    margin-top: 3.125rem;
  }
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  div {
    @include phone {
      flex-basis: 100%;
      margin-top: 1.25rem;
    }
    @include tablet-portrait {
      flex-basis: 100%;
      margin-top: 1.25rem;
    }
    @include tablet-landscape {
      flex-basis: 25%;
    }
    flex-shrink: 0;
    flex-grow: 0;
    label {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      @include Body-1GreyLeft;
      margin: 0;
    }
  }
}

.recall {
  display: flex;
  flex-wrap: wrap;
  label {
    display: inline-flex;
    align-items: baseline;
    flex-basis: 100%;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: bold;
    margin-top: 1.25rem;
  }
  p {
    @include Body-1GreyLeft;
    margin: 0;
    margin-top: 0.25rem;
  }
  hr {
    width: 100%;
    margin-top: 1.25rem;
  }
}

.whatToDo {
  position: relative;
  border-left: 4px solid $red;
  padding-left: 1rem;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  h5 {
    margin: 0;
  }
  img {
    height: 1.875rem;
    width: 1.875rem;
  }
  .links {
    margin-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    h3 {
      @include H3BlackLeft;
      flex-basis: 100%;
      flex-shrink: 0;
    }
    p {
      align-self: center;
      padding-left: 0.25rem;
    }
    div {
      display: inline-flex;
      flex-basis: 100%;
      @include phone {
        flex-wrap: wrap;
      }
      @include tablet-portrait {
        flex-wrap: wrap;
      }
      @include tablet-landscape {
        flex-wrap: nowrap;
      }
    }
    a {
      height: unset;
      @include phone {
        margin-top: 1rem;
        flex-basis: 100%;
      }
      @include tablet-portrait {
        margin-top: 1rem;
        flex-basis: 100%;
      }
      @include tablet-landscape {
        margin-top: 0;
        margin-left: 6.25rem;
        flex-basis: auto;
      }
      img {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
    .phone {
      text-decoration: none;
      font-weight: 500;
      flex-basis: unset;
      margin-top: 0;
      margin-left: 0;
    }
    img {
      padding-right: 0.5rem;
    }
  }
}

.loader {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
