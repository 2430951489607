
* {
  box-sizing: border-box;
}

.container{
  width: 85%;
  max-width: 90rem;
  margin: 0 auto;
  @include mq('sm'){
    width: 90%;
  }
}
.uppercase{
  text-transform: uppercase;
}
.center{
  text-align: center;
}
.spread-text{
  letter-spacing: 2.56px;
}
.mb-1{
  margin-bottom: 1rem;
}
.mb-2{
  margin-bottom: 2rem;
}
.mb-3{
  margin-bottom: 3rem;
}
.pt-2{
  padding-top: 2rem;
}
p{
  a{
    color: $darkGrey;
  }
}

.disclaimer-wrapper{
  p{
    color: darken(white, 40%);
    font-size: 80%;
  }
}
.button-wrapper{
  @include flex();
  &.left{
    justify-content: flex-start;
  }
  &.right{
    justify-content: flex-end;
  }
}
.btn{
    outline: 0;
    border: 0;
    border-radius: 0;
    font-size: 16px;
    font-weight: 700;
    outline: 0;
    box-shadow: none;
    font-family: $font-montserrat;
    width: auto;
    text-decoration: none;
    cursor: pointer;
    span{
      margin-left: .5rem;
    }
    &:focus{
      outline: 0;
      box-shadow: none;
    }
    &:disabled, &.disabled{
      opacity: .4;
      cursor: not-allowed;
    }
    &.solid{
        color: black;
        text-transform: uppercase;
        background-image: linear-gradient(to bottom, $primaryYellow, #f2b923);
        padding: 1rem 2rem;
        border-radius: 10px;
        font-weight: 700;
        box-shadow: 1px 2px 7.84px 0.16px rgba(0, 0, 0, 0.17);
        &:hover {
          background-image: linear-gradient(to bottom, #e6da00, #e6c100);
        }
    }
    &.simple{
      background: none;
      padding: 0;
    }
    &.twitter{
      color: $twitter;
      text-decoration: none;
      font-weight: normal;
      @include flex($display: inline-flex);
      img{
        width: 16px;
      }
    }
}

.tile{
  padding: 2rem;
}

.nowrap{
  display: inline-block;
}

#home{
  font-family: 'Avenir';
  .hero-tile{
    background-image: url('../images/hero-background.jpg');
    width: 100%;
    background-position: top center;
    background-size: cover;
    color: white;
    .desc-wrapper{
      min-height: 3rem;
      font-family: $font-montserrat;
    }
    .header-wrapper{
      text-align: center;
      h1{
        font-family: $font-avenir-next;
        font-size: 3rem;
        font-weight: 600;
      }
      h2{
        letter-spacing: 2px;
        font-weight: 300;
        a{
          color: $primaryYellow;
          text-decoration: none;
          display: inline-block;
          
          strong{
            font-weight: 600;
          }
        }
        &.divider-headline{
          font-size: 20px;
        }
      }
    }
  }
  
  .keypoints-tile{
    .keypoints-wrapper{
      @include flex($align: stretch);
      @include mq('xl'){
        flex-wrap: wrap;
      }
      @include mq('md'){
        flex-direction: column;
        flex-wrap: nowrap;
      }
      margin-bottom: 1rem;
      .keypoint-wrapper{
        width: 25%;
        @include flex($justify: stretch, $direction: column);
        font-family: $font-montserrat;
        a{
          color: $darkGrey;
        }
        .icon-wrapper{
          margin-bottom: 1rem;
          height: 8rem;
          @include mq('md'){
            height: auto;
          }
        }
        .text-wrapper{
          padding: 0 2rem;
          text-align: center;
          line-height: 1.5;
          font-size: 16px;
          border-right: 1px solid rgba(204, 204, 204, .45);
          height: 100%;
          max-width: 18rem;
        }
        &:last-of-type{
          .text-wrapper{
            border-right: 0;
          }
        }
        @include mq('xl'){
          width: 50%;
          padding: 2rem;
          .text-wrapper{
            padding: 0;
            border: 0;
          }
        }
        @include mq('md'){
          width: 100%;
        }
      }
    }
    .title-wrapper{
      margin-bottom: 3rem;
      strong{
        font-weight: 800;
        font-family: $font-avenir-next;
      }
    }
  }
  .quick-facts-tile{
    background-image: url('../images/facts-background.jpg');
    background-size: cover;
    background-position: center;
    .facts-wrapper{
      ul{
        padding: 0;
        margin: 0;
        @include flex($align: flex-start, $justify: flex-start);
        flex-wrap: wrap;
        li{
          padding: .5rem 1.5rem;
          width: 50%;
          list-style-type: none;
          @include mq('md'){
            width: 100%;
          }
        }
      }
      .fact-wrapper{
        p{
          font-family: 'Montserrat';
          line-height: 1.5;
          margin-bottom: .5rem;
        }
      }
    }
  }
}