@import "colors.scss";
@import "mixins.scss";

.container.hidden {
  transform: translateY(100%);
}

.container.mobile {
  height: auto;
}

.container.mobile.open {
  height: auto;
}

.container {
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100vw;
  height: auto;
  background-color: $darkGrey;
  display: flex;
  justify-content: center;
  transition: transform 0.25s ease-out, height 0.25s ease-out;
  // button {
  //   text-transform: uppercase;
  //   font-size: 16px;
  //   font-weight: bold;
  //   text-align: center;
  //   outline: none;
  //   border: none;
  //   cursor: pointer;
  //   color: $darkGrey;
  //   opacity: 1;
  //   background-image: linear-gradient(to bottom, $primaryYellow, #ffd600);
  //   box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  // }
  // button:hover {
  //   background-image: linear-gradient(to bottom, #e6da00, #e6c100);
  // }
}

.copy {
  p {
    line-height: 1.125rem;
  }
}

.desktopContent {
  flex-basis: 85%;
  flex-shrink: 0;
  flex-grow: 0;

  max-width: 90rem;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: normal;

  .copy {
    width: 75%;
    h4 {
      @include H4YellowCenter;
      text-align: left;
      flex-basis: 100%;
      margin-bottom: 0;
    }
    .en {
      margin-top: 1rem;
    }
    .es {
      margin-top: 0.5rem;
    }
    p {
      margin-top: 0.25rem;
      color: $white;
      font-size: 12px;
      flex-basis: 100%;
      a {
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
        color: white;
        outline: none;
      }
    }
  }
  .consent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    margin-right: 0.75rem;
    // button {
    //   height: 2.5rem;
    //   width: 10.75rem;
    // }
  }
}

.closedMobileContent {
  flex-basis: calc(100% - 1rem);
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 90rem;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: normal;
  .readMore {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
  .copy {
    width: 50%;
    margin-right: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    h4 {
      @include H4YellowCenter;
      text-align: left;
      flex-basis: 100%;
      margin-top: 0.75rem;
      margin-bottom: 0;
    }
    p {
      margin-top: 0.25rem;
      color: $white;
      font-size: 12px;
      flex-basis: 100%;
      a {
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
        color: white;
        outline: none;
      }
    }
  }
  .consent {
    display: flex;
    justify-content: center;
    align-items: center;
    // button {
    //   height: 2.5rem;
    //   width: 10.75rem;
    // }
  }
}

.openMobileContent {
  flex-basis: calc(100% - 1rem);
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 90rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;

  .copy {
    width: 100%;
    margin-right: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .collapse {
      cursor: pointer;
      flex-grow: 0;
      padding-top: 0.75rem;
    }
    h4 {
      @include H4YellowCenter;
      text-align: left;
      flex-basis: 75%;
      flex-shrink: 0;
      margin-top: 0.75rem;
      margin-bottom: 0;
    }
    p {
      margin-top: 0.25rem;
      color: $white;
      font-size: 12px;
      flex-basis: 100%;
      a {
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
        color: white;
        outline: none;
      }
    }
  }
  .consent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
    // button {
    //   height: 2.5rem;
    //   width: 100%;
    // }
  }
}
