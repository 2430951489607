@import "colors.scss";

@mixin YellowHover {
  background-image: linear-gradient(to bottom, #e6da00, #e6c100);
}

@mixin H1BlackLeft {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: bold;
  color: $darkGrey;
}

@mixin H1WhiteLeft {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: bold;
  color: $white;
}

@mixin H2BlackLeft {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  color: $black;
}

@mixin H2WhiteLeft {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  color: $white;
}

@mixin H2YellowLeft {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  color: $primaryYellow;
}

@mixin H3BlackCenter {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: $darkGrey;
}

@mixin H3BlackLeft {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  color: $darkGrey;
}

@mixin H3BlackLeft {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: $darkGrey;
}

@mixin H3GreyCenter {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: rgba(23, 23, 23, 0.5);
}

@mixin H3GreyLeft {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  color: rgba(23, 23, 23, 0.5);
}

@mixin Button-LabelDisabled {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $white;
}

@mixin Button-LabelEnabled {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $darkGrey;
}

@mixin H4GreyLeft {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: $darkGrey;
}

@mixin H4WhiteCenter {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: $white;
}

@mixin H4WhiteLeft {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: $white;
}

@mixin H4YellowCenter {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: $primaryYellow;
}

@mixin H5BlackCenter {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: $darkGrey;
}

@mixin H5BlackLeft {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  color: $darkGrey;
  margin: 0;
}

@mixin H5WhiteCenter {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: $white;
}

@mixin H5WhiteLeft {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  color: $white;
}

@mixin Text-LinkBlackLeft {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.57;
  color: $black;
}

@mixin Text-LinkWhiteCenter {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.57;
  color: $white;
}

@mixin Body-1BlackRight {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  text-align: right;
  color: $darkGrey;
}

@mixin Body-1GreyLeft {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  color: $lighterGrey;
}

@mixin Body-1GreyRight {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  text-align: right;
  color: $lighterGrey;
}

@mixin Body-1Light-GreyLeft {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  color: rgba(71, 69, 69, 0.5);
}

@mixin Body-1Light-GreyRight {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  text-align: right;
  color: rgba(71, 69, 69, 0.5);
}

@mixin Body-1WhiteCenter {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: $white;
}

@mixin Body-1WhiteLeft {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: $white;
}

@mixin Subheadline-1GreyLeft {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: $lighterGrey;
}

@mixin Footer-HeaderYellow {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primaryYellow;
}

@mixin phone {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin tablet-portrait {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin tablet-landscape {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin big-tablet-landscape-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin big-tablet-landscape-max {
  @media (min-width: 2000px) {
    @content;
  }
}

@mixin flex($display: flex, $direction: row, $align: center, $justify: center){
  display: $display;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin mq($width, $type: max) {
@if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
        $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
        @content;
    }
}
@else {
  @if $type == max {
      $width: $width - 1px;
  }
  @media only screen and (#{$type}-width: $width) {
      @content;
  }
}
}
