@import "colors.scss";
@import "mixins.scss";

.searchBoxes {
  display: flex;
  flex: 1;
  padding-top: 2rem;
  justify-content: space-between;
}

.vertBoxes {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.25rem;
}

.vertSeperator {
  display: inline-flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-basis: 100%;
  flex-shrink: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;

  hr {
    width: 50%;
  }

  label {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    margin-left: 0.5rem;
    margin-top: -0.2rem;
    padding: 0 0.5rem;
  }
}

.separator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: 2.5rem;

  hr {
    height: 40%;
    margin: 0;
    margin-left: 1em;
  }

  label {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    flex-basis: 100%;
    flex-shrink: 0;
  }
}
