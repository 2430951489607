@import "colors.scss";
@import "mixins.scss";

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app {
  font-family: Montserrat, sans-serif;
  color: $darkGrey;
  scroll-behavior: smooth;
  overflow-x: hidden;
  outline: none;
  @include desktop {
    min-height: calc(100vh - 15rem);
    position: relative;
    padding-bottom: 15rem;
  }
}
