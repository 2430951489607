@import "colors.scss";
@import "mixins.scss";

.div {
  margin-top: 2.5rem;
  .h2 {
    @include H2BlackLeft;
    margin: 0;
    margin-bottom: 1.875rem;
  }
  .hr {
    border: 2px solid $primaryYellow;
    background-color: $primaryYellow;
    border-width: 3px;
    box-shadow: none;
    outline: none;
    margin: 0;
  }
}

